/**
* 被害状況詳細画面用モジュール。
* @module app/damage/damageReportDetail/DamageReportDetailPage
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/data/ItemFileWriteStore',
    'dojo/dom',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/DamageReportDetailPage.html',
    'dojo/topic',
    'dstore/Memory',
    'idis/util/DateUtils',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/store/IdisRest',
    '../_DamageReportRegisterPageBase',
    'app/config',
    'dojox/lang/functional/array',
    'idis/view/dialog/InfoDialog',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/TabContainer',
    'dijit/TitlePane',
    'dijit/layout/ContentPane',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    'app/view/form/OrganizationSelector',
    'app/view/form/MunicipalitySelector',
    'app/view/form/HldOrganizationMultiSelector',
    'app/damage/DamageMunicipalitySelector',
    'app/damage/damageReportAction/DamageReportActionGrid',
    'app/damage/damageReportDetail/DamageDistrictGrid',
    '../DamageReportDeleteDialog',
    'app/damage/damageReportAction/DamageReportActionForm'
], function (module, LayerStore, declare, array, lang, ItemFileWriteStore,
    dom, domClass, domStyle, json, template, topic, Memory, DateUtils, Locator, Router, UserInfo,
    Requester, DialogChain, Loader, IdisRest, _DamageReportRegisterPageBase, config, df, InfoDialog) {

    /**
    * 被害状況詳細画面。
    * @class DamageReportDetailPage
    * @extends module:app/damage/_DamageReportPageBase~_DamageReportPageBase
    */
    return declare(module.id.replace(/\//g, '.'), _DamageReportRegisterPageBase,
        /** @lends module:app/damage/damageReportDetail/DamageReportDetailPage~DamageReportDetailPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            // 続報番号一覧
            seqNumStore: null,

            // 対応情報のストア
            actionStore: null,

            // 地区ストア
            districtStore: null,

            //被害基本情報を保持
            damageBasicInfo: {
                admNum: null,
                humanDamageFlg: null,
                notifierCategory: null,
                notifierName: null,
                notifierContact: null,
                damageTimestamp: null,
                urgencyType: null,
                damageType: null,
                content: null
            },

            /**
             * 親災害ID
             */
            _parentDamageId: null,

            //過去報と違う項目のリスト
            _updatedPropertiesList: [],

            //報告状況
            _reportStatus: null,
            _isChangeReportStatus: false,

            /**
             * @type {Object}
             */
            _timer: null,

            //対応情報登録のためにユーザの組織名を保持
            organizationCd: null,
            organizationName: null,

            changedDamageType: null,

            // 北海道連携用
            _extraDisasterId: null,
            _extDamageReportId: null,
            _extAdamNo: null,

            // 災害シミュレーション用
            _simulateFlg: false,
            _scenarioId: null,
            _eventId: null,

            // 大阪市 水害地区一覧（初回のみ)
            _initLifelineDistList: [],
            constructor: function () {
                this.chain = DialogChain.get(this);
                // URLから被害状況IDを取得
                this._damageReportId = Locator.getQuery().damageReportId;
                // データ格納用オブジェクト
                this.actionStore = new IdisRest({
                    idProperty: 'damageReportActionId',
                    target: '/api/damageReports/action/' + this._admNum
                });
                // URLから災害シミュレーション用のパラメータを取得
                this._simulateFlg = Locator.getQuery().simulateFlg;
                this._scenarioId = Locator.getQuery().scenarioId;
                this._eventId = Locator.getQuery().eventId;
            },

            /**
             * buildRendering
             */
            buildRendering: function () {
                this.inherited(arguments);
                this.own(this.damageReportDeleteDialog);

            },

            /**
             * startup
             */
            startup: function () {
                this.inherited(arguments);

                // 被害状況詳細画面を初期化
                this.initDetailPage(true);

                this.own(topic.subscribe(this.DAMAGE_GRID_TYPE_SELECTED, lang.hitch(this, function (data) {
                    this.setDamageType(data);
                })));

                this.own(topic.subscribe(this.DAMAGE_GRID_TYPE_RELEASED, lang.hitch(this, function (data) {
                    this.releaseDamageType(data);
                })));

                //続報セレクタに変更イベントを設定
                this.seqNumSelect.on('change', lang.hitch(this, function (evt) {
                    this.onSeqNumChange(evt);
                }));

                //削除対象選択ダイアログに変更イベントを追加
                var deletePage = this.innerDeleteDialog;
                deletePage.on('send', lang.hitch(this, function (value) {
                    Requester.get('./api/damageReports/checkDeleteReport?admNum=' + this._admNum)
                        .then(lang.hitch(this, function (data) {
                            if (!data && value.deleteTarget === "2") {
                                this.chain.info('確定報が存在するため、削除できません', 'エラー');
                                return;
                            } else {
                                this.deleteDamageReport(value.deleteTarget);
                            }
                        }), lang.hitch(this, function (error) {
                            console.log(error);
                            self.chain.info('情報の取得に失敗しました。', 'エラー');
                        }));
                }));

                // 数値報告初期設定
                this.damageReportNumberDialog.on('send', lang.hitch(this, function (evt) {
                    this._numberData = evt.formData;
                    //数値情報の全ての項目が0の場合、未入力ラベルを表示する
                    if(this.checkNumberData(this._numberData)){
                        this.numberEntered.style.display = 'none';
                        this.numberNotEntered.style.display = '';
                    //1項目でも1以上の数値の場合、数値入力がある項目のラベル編集処理を呼び出す
                    }else{
                        this.numberEntered.style.display = '';
                        this.numberNotEntered.style.display = 'none';
                        this.setNumberDataLabel(this._numberData);
                    }
                }));
                this.own(this.innerDamageReportNumber);

                //対応履歴フォームのイベントリスナーをセット
                this.initDamageReportActionForm();

            },

            // 詳細画面の初期表示
            initDetailPage: function (isFirstTime) {
                //初期化
                this.pointLat = '';
                this.pointLng = '';
                if (typeof this.marker === 'undefined') {
                    this.marker = '';
                }

                this.attachFileList = [];
                this.updatedPropertiesList = [];

                // プレビューを初期化
                this.clearPreview();

                //過去報に切り替える場合は、強調表示を無効化する
                if (!isFirstTime && this._updatedPropertiesList.length > 0) {
                    array.forEach(this._updatedPropertiesList, function (propName) {
                        if (propName === 'organization' || propName === 'reportAuthorName') {
                            propName = 'preAuthor';
                        }
                        if (propName === 'reportUpdTimestamp') {
                            propName = 'preReportTimestamp';
                        }
                        if (dom.byId(propName + 'Label')) {
                            domClass.remove(dom.byId(propName + 'Label'), 'is-changed');
                        }
                    });
                }
                var self = this;
                if (this._simulateFlg === 'true') {
                    // 添付ファイル、作図を不可視にする
                    domStyle.set(this.attachFileItem, 'display', 'none');
                    domStyle.set(this.showDrawButton, 'display', 'none');
                    this.updateStatusButton.set('style', { display: 'none' });
                    // 被害情報を取得
                    Requester.get('/data/simulation/' + this._eventId + '.json')
                        .then(function (item) {
                            var data = item.damageReportPostForm;
                            data.subData = item.damageReportConversionForm;
                            self.initDetailPageValue(data, isFirstTime);
                        }, function (error) {
                            console.log(error);
                            self.chain.info('被害イベントの取得に失敗しました。', 'エラー');
                        }).then(function () {
                            //前報から変更があった項目リストを強調表示する
                            if (self.updatedPropertiesList.length > 0) {
                                array.forEach(self.updatedPropertiesList, function (propName) {
                                    if (propName === 'organization' || propName === 'reportAuthorName') {
                                        propName = 'preAuthor';
                                    }
                                    if (propName === 'reportTimestamp') {
                                        propName = 'preReportTimestamp';
                                    }
                                    if (dom.byId(propName + 'Label')) {
                                        domClass.add(dom.byId(propName + 'Label'), 'is-changed');
                                    }
                                    // }
                                });
                            }
                        });
                } else {
                    // 被害情報を取得
                    Requester.get('/api/damageReports/' + this._damageReportId, {
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(function (data) {
                        self.initDetailPageValue(data, isFirstTime);
                    }, function (error) {
                        console.log(error);
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    }).then(function () {
                        //前報から変更があった項目リストを強調表示する
                        if (self.updatedPropertiesList.length > 0) {
                            array.forEach(self.updatedPropertiesList, function (propName) {
                                if (propName === 'organization' || propName === 'reportAuthorName') {
                                    propName = 'preAuthor';
                                }
                                if (propName === 'reportTimestamp') {
                                    propName = 'preReportTimestamp';
                                }
                                if (dom.byId(propName + 'Label')) {
                                    domClass.add(dom.byId(propName + 'Label'), 'is-changed');
                                }
                            });
                        }
                    });
                }

                // 大阪市：
                this.innerDamageGridTypeSelector.showClearBtn(false);
                this.innerDamageGridTypeSelector.showSelectBtn(false);

                // 大阪市：区役所ユーザは区の変更が不可
                if (UserInfo.getApprovalType() !== "1") {
                    this.overviewLabel.style.display = 'none';
                }
            },

            // 詳細画面の初期値設定
            initDetailPageValue: function (item, isFirstTime) {
                var self = this;
                self._item = item;
                // 市町名をHTMLに移す
                self.municipalityCd.set('value', item.municipalityCd);

                self.setReportStatus(item.reportStatus);
                // 「マスコミ公開可能」のものは、報告状況を変更不可能
                if (item.reportStatus === '41') {
                    self.updateStatusButton.set('style', { display: 'none' });
                    self.reportOrganization.set('noAllButton', 'true');
                    if (UserInfo.getApprovalType() === '1' || UserInfo.getApprovalType() === '2') {
                        self.registReportBtn.set('style', { display: '' });
                        if (item.sendingId) {
                            self.registReportBtn.set('disabled', true);
                        }
                    }
                } else if (item.multiWardDamageFlg) {
                    self.updateStatusButton.set('style', { display: 'none' });
                } else {
                    self.registReportBtn.set('style', { display: 'none' });
                    self.updateStatusButton.set('style', { display: '' });
                }
                // 所属確認済み or 区本部確認待ち
                if (item.reportStatus === '31' || item.reportStatus === '32') {
                    // 管理者・区本部ユーザ以外は変更不可
                    if (UserInfo.getApprovalType() !== '1' && UserInfo.getApprovalType() !== '2') {
                        self.updateStatusButton.set('disabled', true);
                    }
                    // 区本部ユーザで自分の区でない場合変更不可
                    if (UserInfo.getApprovalType() === '2' && item.municipalityCd !== UserInfo.getMunicipalityCd()) {
                        self.updateStatusButton.set('disabled', true);
                    }
                }
                // 所属確認待ち
                if (item.reportStatus === '12') {
                    // 管理者・所属本部ユーザ以外は変更不可
                    if (UserInfo.getApprovalType() !== '1' && UserInfo.getApprovalType() !== '3') {
                        self.updateStatusButton.set('disabled', true);
                    }
                    // 自所属でない場合変更不可
                    if (UserInfo.getApprovalType() === '3' && item.deptCd !== UserInfo.getDeptCd()) {
                        self.updateStatusButton.set('disabled', true);
                    }
                }

                if (self._simulateFlg) {
                    self.updateStatusButton.set('style', { display: 'none' });
                }

                self.districtStore = new Memory({
                    idProperty: 'damageLifelineDistId',
                });
                if (item.lifelineDistList !== null) {
                    if (this.tabContainer.tablist.containerNode.childElementCount === 2) {
                        this.tabContainer.addChild(this.tab3);
                    }
                    item.lifelineDistList.forEach(function (item) {
                        self.districtStore.addSync(item);
                    });
                } else {
                    if (this.tabContainer.tablist.containerNode.childElementCount > 2) {
                        this.tabContainer.removeChild(this.tab3);
                    }
                }
                self.districtGrid.set('collection', self.districtStore);
                /* 複数区にまたがる被害 */
                if (item.multiWardDamageFlg) {
                    self.updateStatusButton.set('style', { display: 'none' });

                    self.reportStatus12.style.display = item.multiWardReportStatus === '12' ? '' : 'none';
                    self.reportStatus31.style.display = item.multiWardReportStatus === '31' ? '' : 'none';
                    self.reportStatus32.style.display = item.multiWardReportStatus === '32' ? '' : 'none';
                    self.reportStatus41.style.display = item.multiWardReportStatus === '41' ? '' : 'none';


                    // 所属確認待ち
                    if (self.updateReportStatusButton) {
                        if (item.multiWardReportStatus === '12') {
                            self.updateStatusButton.set('style', { display: '' });
                            // 管理者・所属本部ユーザ以外は変更不可
                            if (UserInfo.getApprovalType() !== '1' && UserInfo.getApprovalType() !== '3') {
                                self.updateStatusButton.set('disabled', true);
                            }
                            // 自所属でない場合変更不可
                            if (UserInfo.getApprovalType() === '3' && item.deptCd !== UserInfo.getDeptCd()) {
                                self.updateStatusButton.set('style', { display: '' });
                                self.updateStatusButton.set('disabled', true);
                            }
                        }

                        // 所属確認済み or 区本部確認待ち
                        if (item.multiWardReportStatus === '31' || item.multiWardReportStatus === '32') {
                            self.updateReportStatusButton.set('style', { display: '' });
                            // 管理者・区本部ユーザ以外は変更不可
                            if (UserInfo.getApprovalType() !== '1' && UserInfo.getApprovalType() !== '2') {
                                self.updateReportStatusButton.set('disabled', true);
                            }
                            // 区本部ユーザで自分の区でない場合変更不可
                            if (UserInfo.getApprovalType() === '2' && item.municipalityCd !== UserInfo.getMunicipalityCd()) {
                                self.updateReportStatusButton.set('disabled', true);
                            }
                        }
                        // 確定済み
                        if (item.multiWardReportStatus === '41') {
                            self.updateReportStatusButton.set('style', { display: 'none' });
                        }
                    }

                    if (self.updateMultiWard1StatusButton) {
                        // 所属確認済み or 区本部確認待ち
                        if (item.multiWardReportStatus1 === '31' || item.multiWardReportStatus1 === '32') {
                            self.updateMultiWard1StatusButton.set('style', { display: '' });
                            // 管理者・区本部ユーザ以外は変更不可
                            if (UserInfo.getApprovalType() !== '1' && UserInfo.getApprovalType() !== '2') {
                                self.updateMultiWard1StatusButton.set('disabled', true);
                            }
                            // 区本部ユーザで自分の区でない場合変更不可
                            if (UserInfo.getApprovalType() === '2' && item.multiWardmunicipalityCd1 !== UserInfo.getMunicipalityCd()) {
                                self.updateMultiWard1StatusButton.set('disabled', true);
                            }
                        }

                        // 確定済み
                        if (item.multiWardReportStatus1 === '41') {
                            self.updateMultiWard1StatusButton.set('style', { display: 'none' });
                        }

                    }
                    if (self.updateMultiWard2StatusButton) {
                        // 所属確認済み or 区本部確認待ち
                        if (item.multiWardReportStatus2 === '31' || item.multiWardReportStatus2 === '32') {
                            self.updateMultiWard2StatusButton.set('style', { display: '' });
                            // 管理者・区本部ユーザ以外は変更不可
                            if (UserInfo.getApprovalType() !== '1' && UserInfo.getApprovalType() !== '2') {
                                self.updateMultiWard2StatusButton.set('disabled', true);
                            }
                            // 区本部ユーザで自分の区でない場合変更不可
                            if (UserInfo.getApprovalType() === '2' && item.multiWardmunicipalityCd2 !== UserInfo.getMunicipalityCd()) {
                                self.updateMultiWard2StatusButton.set('disabled', true);
                            }
                        }

                        // 確定済み
                        if (item.multiWardReportStatus2 === '41') {
                            self.updateMultiWard2StatusButton.set('style', { display: 'none' });
                        }
                    }

                    if (self.updateMultiWard3StatusButton) {
                        // 所属確認済み or 区本部確認待ち
                        if (item.multiWardReportStatus3 === '31' || item.multiWardReportStatus3 === '32') {
                            self.updateMultiWard3StatusButton.set('style', { display: '' });
                            // 管理者・区本部ユーザ以外は変更不可
                            if (UserInfo.getApprovalType() !== '1' && UserInfo.getApprovalType() !== '2') {
                                self.updateMultiWard3StatusButton.set('disabled', true);
                            }
                            // 区本部ユーザで自分の区でない場合変更不可
                            if (UserInfo.getApprovalType() === '2' && item.multiWardmunicipalityCd3 !== UserInfo.getMunicipalityCd()) {
                                self.updateMultiWard3StatusButton.set('disabled', true);
                            }
                        }

                        // 確定済み
                        if (item.multiWardReportStatus3 === '41') {
                            self.updateMultiWard3StatusButton.set('style', { display: 'none' });
                        }
                    }
                } else {
                    if (self.updateReportStatusButton) {
                        self.updateReportStatusButton.set('style', { display: 'none' });
                    }
                }

                // 被害詳細データをセット
                self.form.set('value', item);
                self.innerDamageReportNumber.form.set('value', item.numberData);
                if (item.numberData && item.numberData.humanDamageList) {
                    self.innerDamageReportNumber._humanDamageList = item.numberData.humanDamageList;
                } else {
                    self.innerDamageReportNumber._humanDamageList = [];
                }

                self.publicFacility.set('checked', item.publicFacility);
                self._numberData = item.numberData;
                self._initLifelineDistList = item.lifelineDistList;

                //数値情報の全ての項目が0の場合、未入力ラベルを表示する
                if (this.checkNumberData(self._numberData)) {
                    this.numberEntered.style.display = 'none';
                    this.numberNotEntered.style.display = '';
                //1項目でも1以上の数値の場合、数値入力がある項目のラベル編集処理を呼び出す
                }else{
                    this.numberEntered.style.display = '';
                    this.numberNotEntered.style.display = 'none';
                    this.setNumberDataLabel(this._numberData);
                }

                self.multiMunicipalitySection.style.display = item.multiWardDamageFlg ? '' : 'none';
                if (item.multiWardDamageFlg) {
                    self.reportStatus12.style.display = item.multiWardReportStatus === '12' ? '' : 'none';
                    self.reportStatus31.style.display = item.multiWardReportStatus === '31' ? '' : 'none';
                    self.reportStatus32.style.display = item.multiWardReportStatus === '32' ? '' : 'none';
                    self.reportStatus41.style.display = item.multiWardReportStatus === '41' ? '' : 'none';

                    if (item.multiWardmunicipalityCd1) {
                        self.multiMunicipalityCd1.set('value', item.multiWardmunicipalityCd1);
                        self.multiWard1Status12.style.display = item.multiWardReportStatus1 === '12' ? '' : 'none';
                        self.multiWard1Status31.style.display = item.multiWardReportStatus1 === '31' ? '' : 'none';
                        self.multiWard1Status32.style.display = item.multiWardReportStatus1 === '32' ? '' : 'none';
                        self.multiWard1Status41.style.display = item.multiWardReportStatus1 === '41' ? '' : 'none';
                    }

                    if (item.multiWardmunicipalityCd2) {
                        self.multiMunicipalityCd2.set('value', item.multiWardmunicipalityCd2);
                        self.multiWard2Status12.style.display = item.multiWardReportStatus2 === '12' ? '' : 'none';
                        self.multiWard2Status31.style.display = item.multiWardReportStatus2 === '31' ? '' : 'none';
                        self.multiWard2Status32.style.display = item.multiWardReportStatus2 === '32' ? '' : 'none';
                        self.multiWard2Status41.style.display = item.multiWardReportStatus2 === '41' ? '' : 'none';
                    }
                    if (item.multiWardmunicipalityCd3) {
                        self.multiMunicipalityCd3.set('value', item.multiWardmunicipalityCd3);
                        self.multiWard3Status12.style.display = item.multiWardReportStatus3 === '12' ? '' : 'none';
                        self.multiWard3Status31.style.display = item.multiWardReportStatus3 === '31' ? '' : 'none';
                        self.multiWard3Status32.style.display = item.multiWardReportStatus3 === '32' ? '' : 'none';
                        self.multiWard3Status41.style.display = item.multiWardReportStatus3 === '41' ? '' : 'none';
                    }
                }

                this.reportOrganization.set('value', item.organization);
                if (item.organization === "DD89013") {
                    this.reportOrganization.status.innerHTML = "外部連携";
                }
                //this.reportOrganization.innerHTML = item.organizationName;
                this.reportAuthorName.innerHTML = item.reportAuthorName;
                // 前回報告日時
                var reportDateTime = new Date(item.reportUpdTimestamp);
                this.preReportTimestamp.innerHTML = DateUtils.format(reportDateTime, {time: 'HH:mm'});
                // 報告日時
                this.reportTimestamp.set('value', null);
                //対応履歴登録時のため、ユーザの組織情報を取得
                if (isFirstTime) {
                    self.organizationCd = item.userOrganizationCd;
                    self.organizationName = item.userOrganizationName;
                }

                // 直近参集者かチェック
                item.recentattendeesFlg = false;
                if (UserInfo.getMobilizationType() > 50 && UserInfo.getMobilizationType() < 75){
                    // 直近参集者情報を取得 
                    var self = this;            
                    Requester.get('/api/recentattendees/mobilization/' + UserInfo.getMobilizationType())
                    .then(lang.hitch(this, function (ramu) {
                        if (ramu.municipalityCd.toString() === item.municipalityCd){
                            item.recentattendeesFlg = true;
                        }
                        // 訂正・続報登録・削除ボタンの活性/非活性切り替え制御
                        self.setButtonStatus(item);
                    }), function (error) {
                        self.chain.infoError(error);
                    });
                } else {
                    // 訂正・続報登録・削除ボタンの活性/非活性切り替え制御
                    this.setButtonStatus(item);                        
                }   

                //被害情報(基本情報)を保存
                lang.hitch(self, self.setDamageInfoForm(item));

                if (isFirstTime) {
                    //（初回のみ）ユーザ・対応状況に応じてタブ表示を制御
                    if (this._damageReportId) {
                        var defaultTab = self.tab2;
                        setTimeout(function () {
                            self.tabContainer.selectChild(defaultTab);
                        }, 1500);
                    } else {
                        // シミュレーションの場合は対応履歴のタブを表示しない
                        this.tabContainer.removeChild(this.tab2);
                    }
                }

                // 親管理番号を画面で保持して、設定されている場合はセレクターの表示を切り替える。
                self._parentDamageId = item.parentAdmNum;

                //地図の中心座標を取得
                var lat = config.map.latitude;
                var lng = config.map.longitude;
                var markerIsSet = false;
                self._municipalityCd = item.municipalityCd;

                if (item.damageLatitude && item.damageLongitude) {
                    lat = item.damageLatitude;
                    lng = item.damageLongitude;
                    markerIsSet = true;
                    //初回は初期化を、2回目以降はsetViewのみを行う
                    if (isFirstTime) {
                        self.initMap(lat, lng);
                    } else {
                        self.map.setView([lat, lng], 14);
                    }
                    self.setDamageType({
                        mainTypeIds: item.damageTypeList.split(',')
                    }, true);


                } else if (item.damageAddress) {
                    this.damageAddressToMap();
                    markerIsSet = true;
                    //初回は初期化を、2回目以降はsetViewのみを行う
                    if (isFirstTime) {
                        self.initMap(lat, lng);
                    } else {
                        self.map.setView([lat, lng], 14);
                    }
                    self.setDamageType({
                        mainTypeIds: item.damageTypeList.split(',')
                    }, true);
                } else if (self._municipalityCd && isFirstTime) {
                    // 緯度経度の情報がない場合には、市町の自治体所在地に遷移(初回のみ)
                    Requester.get('/api/municipalities/' + self._municipalityCd, {
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(function (municipality) {
                        // マップを初期化
                        if (municipality.latitude && municipality.longitude) {
                            lat = municipality.latitude;
                            lng = municipality.longitude;
                        }
                        self.initMap(lat, lng);
                        self.setDamageType({
                            mainTypeIds: self._damageTypeList.split(',')
                        }, true);

                    }, function (error) {
                        console.log(error);
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    });
                }
                //マーカーがセットされている場合の処理
                if (markerIsSet) {
                    self.pointLat = lat;
                    self.pointLng = lng;
                    self._damageAddressPointLat = lat;
                    self._damageAddressPointLng = lng;
                    self.addMark(lat, lng);
                } else {
                    self.removeMark();
                }

                // その他情報を保持
                self._admNum = item.admNum;
                self._seqNum = item.seqNum;
                self._extAdmNum = item.extAdmNum;
                self._damageType = item.damageType;
                self._damageTypeList = item.damageTypeList;
                self._chronologyId = item.chronologyId;

                // 添付ファイルを設定
                if (item.attachFiles) {
                    for (var i = 0; i < item.attachFiles.length; i++) {
                        self.attachFileList.push(item.attachFiles[i]);
                        self.showPreview(item.attachFiles[i], false);
                    }
                }

                //前報から変更があった項目リストを設定
                if (item.updatedProperties) {
                    for (var j = 0; j < item.updatedProperties.length; j++) {
                        self.updatedPropertiesList.push(item.updatedProperties[j]);
                    }
                    self._updatedPropertiesList = self.updatedPropertiesList;
                }

                if (this._simulateFlg !== 'true') {
                    // 対応情報グリッドを初期化
                    self.initDamageReportActionGrid(item.admNum, item.seqNum);
                }


                //(初回のみ)続報リストを取得・続報セレクタにセット
                if (isFirstTime && item.delFlg !== 'true' && this._simulateFlg !== 'true') {
                    Requester.get('/api/damageReports/' + item.admNum + '/damageInfos', {
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(function (data) {
                        var selectData = [];
                        selectData = Object.keys(data).map(
                            lang.hitch(this, function (key) {
                                return {
                                    seqNum: String(key),
                                    seqNumStr: '第' + String(key) + '報',
                                    damageReportId: data[key]
                                };
                            }));

                        var selectContent = {
                            identifier: 'damageReportId',
                            label: 'seqNumStr',
                            items: selectData
                        };

                        var damageReportItemFileWriteStore = new ItemFileWriteStore({
                            data: selectContent
                        });
                        self.seqNumStore = damageReportItemFileWriteStore;
                        self.seqNumSelect.set('sortByLabel', false);
                        self.seqNumSelect.set('store', damageReportItemFileWriteStore);
                        self.seqNumSelect.set('value', self._damageReportId);

                    }, function (err) {
                        console.log(err);
                    });
                }

                //削除済み被害の場合の報数セレクタ設定
                if (item.delFlg === 'true') {
                    var damageReportItemFileWriteStore = new ItemFileWriteStore({
                        data: {
                            identifier: 'damageReportId',
                            label: 'seqNumStr',
                            items: [{
                                damageReportId: self._damageReportId,
                                seqNumStr: '第' + String(item.seqNum) + '報'
                            }]
                        }
                    });
                    self.seqNumSelect.set('store', damageReportItemFileWriteStore);
                }

                //セレクタへの値のセットは毎回実施
                self.seqNumSelect.set('value', self._damageReportId);

                //以下、被害情報IDに無関係のため初回のみ実施
                if (isFirstTime) {
                    if (self._damageReportId) {
                        //周辺被害表示ボタンを押下可能に
                        self.enabledAroundDamageButton = true;


                    }
                }

                // 作図jsonの読み込み
                // /data/out/damage/[disaster-id]/draws-[admnum]-[seqnum].geojson
                var drawFileUrl = 'data/damage/' + item.disasterId + '/' +
                    'draws-' + item.admNum + '-' + item.seqNum + '.geojson';

                Requester.get(drawFileUrl, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true

                }).then(function (data) {
                    topic.publish(self.REMOVE_ALL);
                    topic.publish(self.DRAW_BY_JSON, data);

                });
            },

            /**
             * 被害情報項目のHTMLをセット・変数に保持
             */
            setDamageInfoForm: function (item) {

                this.admNum.innerHTML = item.admNum || '&nbsp';

                if (item.urgencyType === '0') {
                    this.urgencyType.innerHTML = '-';
                    this.urgencyType.style.backgroundColor = '#ffffff';
                    this.urgencyType.style.color = '#000000';
                }
                else if (item.urgencyType === '1') {
                    this.urgencyType.innerHTML = '低';
                    this.urgencyType.style.backgroundColor = '#ffffff';
                    this.urgencyType.style.color = '#000000';
                } else if (item.urgencyType === '2') {
                    this.urgencyType.innerHTML = '中';
                    this.urgencyType.style.backgroundColor = '#ffff00';
                    this.urgencyType.style.color = '#000000';
                } else if (item.urgencyType === '3') {
                    this.urgencyType.innerHTML = '高';
                    this.urgencyType.style.backgroundColor = '#cc2800';
                    this.urgencyType.style.color = '#ffffff';
                }
                var damageTypeArr = item.damageTypeList.split(',');
                if (damageTypeArr) {
                    this.innerDamageGridTypeSelector.setDamageType(damageTypeArr);
                }

                //フォーム内容を保管
                lang.mixin(this.damageBasicInfo, item);

            },


            /**
             * 訂正・続報登録・削除・復元ボタンの活性/非活性・表示/非表示を初期化する。
             */
            setButtonStatus: function (item) {
                var activeFlg = item.activeFlg;
                var deleteFlg = item.delFlg;
                var releaseFlg = item.reportStatus === '41' ? true : false;
                var updatable = true;

                //削除済み被害の場合は更新ではなく復元
                if (updatable && deleteFlg !== 'false') {
                    updatable = false;
                }

                // 操作権限がない場合
                if (!UserInfo.hasWriteAuthz('F05007')) {
                    updatable = false;
                }
                // 大阪市：被害と異なる区かつ、区のユーザ
                if (item.municipalityCd !== UserInfo.getMunicipalityCd() && UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                    if (item.recentattendeesFlg === false){
                        updatable = false;
                    }
                }
                domStyle.set(this.correctButton.domNode, 'display', updatable && !releaseFlg ? '' : 'none');
                domStyle.set(this.subButton.domNode, 'display', (updatable && activeFlg !== '0') ? '' : 'none');
                domStyle.set(this.deleteButton.domNode, 'display', updatable && !releaseFlg ? '' : 'none');
                domStyle.set(this.buttonsPane.domNode, 'display', '');

                if (this._simulateFlg === 'true') {
                    domStyle.set(this.correctButton.domNode, 'display', '');
                    domStyle.set(this.subButton.domNode, 'display', 'none');
                    domStyle.set(this.deleteButton.domNode, 'display', 'none');
                }
                // 道路以外は矢印線を非表示
                this.showBothArrowDrawButton.style.display = (item.damageType === "09" ? '' : 'none');
                this.showArrowDrawButton.style.display = (item.damageType === "09" ? '' : 'none');
            },

            /**
             * 被害状況を訂正する。
             */
            correctDamageReport: function () {
                console.debug('[訂正]ボタンがクリックされました。');
                this.updateDamageReport(false);
            },

            /**
             * 被害状況（続報）を登録する。
             */
            followupDamageReport: function () {
                console.debug('[続報登録]ボタンがクリックされました。');
                this.updateDamageReport(true);
            },


            /**
             * 被害状況を更新する。
             */
            updateDamageReport: function (followupFlg) {

                var sendData = this.form.get('value');
                console.log(sendData);

                //状況報告（damageType = 19）以外の場合の入力チェック
                if(this._damageType !== '19') {
                    // 被害規模
                    if(sendData.damageScale === ""){
                        console.debug('被害規模が選択されていません');
                        if(!this.infoDialog){
                            this.infoDialog = new InfoDialog({
                                title: 'エラー',
                                content: '被害規模が選択されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }

                    // 深刻度
                    if(sendData.urgencyType === ""){
                        console.debug('深刻度が選択されていません');
                        if(!this.infoDialog){
                            this.infoDialog = new InfoDialog({
                                title: 'エラー',
                                content: '深刻度が選択されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
                }

                // 大阪市 報告者氏名
                sendData.reportAuthorName = UserInfo.getName();
                // 報告部署にログインユーザの組織情報を設定
                var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                    UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                        UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

                // 大阪市 報告組織
                sendData.reportOrganization = this.reportOrganization.get('value');

                // 大阪市 電気
                sendData.elecDmgFlg = parseInt(this.elecDmgFlg.value);
                // 大阪市 水道
                sendData.gasDmgFlg = parseInt(this.gasDmgFlg.value);
                // 大阪市 ガス
                sendData.waterDmgFlg = parseInt(this.waterDmgFlg.value);
                // 大阪市 電話
                sendData.phoneDmgFlg = parseInt(this.phoneDmgFlg.value);

                // 大阪市 市民利用施設
                sendData.publicFacility = this.publicFacility.get('checked');


                //作図をJSON化
                topic.publish(this.JSONIZE_RQST);

                if (!sendData.reportName) {
                    this.setReportName(sendData);
                }

                if (sendData.damageAddress) {
                    sendData.damageLatitude = this._damageAddressPointLat;
                    sendData.damageLongitude = this._damageAddressPointLng;
                } else {
                    sendData.damageLatitude = '';
                    sendData.damageLongitude = '';
                }

                //フォーム上にない項目をセット
                sendData.parentAdmNum = this._parentDamageId;
                sendData.disasterId = this._disasterId;
                sendData.municipalityCd = this._municipalityCd;
                sendData.seqNum = this._seqNum;
                sendData.extAdmNum = this._extAdmNum;
                sendData.damageType = this._damageType;
                sendData.damageTypeList = this._damageTypeList;
                sendData.reportStatus = this._reportStatus;

                // 「確定フラグ」「本部確認状況フラグ」は現段階では使わないので固定値をセット
                sendData.fixFlg = '1';
                sendData.headquarterCheckFlg = '0';

                sendData.organization = this.organizationCd;

                // 添付ファイルIDをセット
                if (this.attachFileList.length > 0) {
                    var attachFileIds = [];
                    for (var i = 0; i < this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].damageReportAtcFileId);
                    }
                    sendData.attachFileIds = attachFileIds.join(',');
                } else {
                    sendData.attachFileIds = '';
                }

                delete sendData.organizationName;
                delete sendData.municipalityName;
                delete sendData.attachFile;

                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを行う（独自チェック）
                if (!this.validateForm(sendData)) {
                    return false;
                }

                var oldExtraDisasterId = '';

                // 大阪市：数値登録のダイアログ入力結果
                sendData.numberData = this._numberData;
                if (this._numberData && Array.isArray(sendData.numberData.humanDamageList)) {
                    // 送信データに不要なgridRowIdの項目を削除する
                    sendData.numberData.humanDamageList =
                        sendData.numberData.humanDamageList.map(function (item) {
                            delete item.gridRowId;
                            return item;
                        });
                }

                // 大阪市 断水地区一覧
                sendData.lifelineDistList = this.getLifelineDistList();

                // 被害報 ステータス変更
                sendData.isChangeReportStatus = this._isChangeReportStatus;

                var self = this;
                var message = '';
                sendData.multiWardDamageFlg = false; //this._item.multiWardDamageFlg;

                sendData.damageReportId = this._damageReportId;
                if (followupFlg) {

                    //続報登録時はversionを送信しない
                    delete sendData.version;
                    var jsonStr = json.stringify(sendData);


                    //登録確認ダイアログを表示
                    this.chain.confirm(this.setConfMsg(sendData, oldExtraDisasterId, true),
                        function (chain) {
                            var promise = Requester.post('/api/damageReports', {
                                data: jsonStr,
                                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                                handleAs: 'json',
                                preventCache: true
                            }).then(function (data) {
                                console.debug('success register DamageReport');
                                message = '完了しました。';
                                //                       }
                                // 作図情報を登録
                                var param = '?damageReportId=' + data.damageReportId;
                                return Requester.post('/api/damageReports/uploadjson' + param, {
                                    data: self.drawJson,
                                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                                    handleAs: 'json',
                                    preventCache: true
                                }).then(function () {
                                    console.debug('success upload json');
                                    chain.info(message, '完了', function () {
                                        //chain.infoComplete(function() {
                                        //ツリーの更新
                                        LayerStore.refreshAll();
                                        // 一覧画面に移動
                                        if (history.length === 1) {
                                            Router.moveTo('report');
                                        } else {
                                            // 一覧画面に移動
                                            self.leave();
                                        }
                                    });
                                }, function (err) {
                                    console.log(err);
                                    chain.info('作図ファイルの登録に失敗しました。', 'エラー', function () {
                                        // 一覧画面に移動
                                        if (history.length === 1) {
                                            Router.moveTo('report');
                                        } else {
                                            // 一覧画面に移動
                                            self.leave();
                                        }
                                    });
                                });
                            }, function (error) {
                                console.log('error register damagereport');
                                chain.infoError(error);
                            });
                            //ローダーの表示
                            Loader.wait(promise);
                        });
                } else if (this._simulateFlg === 'true') {
                    delete sendData.version;
                    var subData = {};

                    subData.municipalityName = this.municipalityCd.domNode.innerText.replace('選択', '');
                    subData.damageTypeNameList = this.DamageGridTypeSelector.innerText.replace('選択', '').replace('解除', '');
                    subData.urgencyTypeName = this.urgencyType.domNode.innerText;

                    switch (this._reportStatus) {
                        case '11':
                            subData.reportStatusName = this.reportedStatus11.innerHTML;
                            break;
                        case '31':
                            subData.reportStatusName = this.reportedStatus31.innerHTML;
                            break;
                        case '32':
                            subData.reportStatusName = this.reportedStatus32.innerHTML;
                            break;
                        case '41':
                            subData.reportStatusName = this.reportedStatus41.innerHTML;
                            break;
                        default:
                    }
                    switch (this.form.value.hldStatus) {
                        case '0':
                            subData.hldStatusName = '確認中';
                            break;
                        case '0':
                            subData.hldStatusName = '対応待ち';
                            break;
                        case '0':
                            subData.hldStatusName = '対応中';
                            break;
                        case '0':
                            subData.hldStatusName = '対応済み';
                            break;
                        default:

                    }
                    //更新確認ダイアログを表示
                    message = 'イベントを訂正します。<br>よろしいですか。';
                    this.chain.confirmPut(function (chain) {
                        var jsonStr = json.stringify({
                            damageReportPostForm: sendData,
                            damageReportConversionForm: subData,
                            scenarioId: this._scenarioId,
                            eventId: this._eventId
                        });
                        var promise = Requester.put('/api/simulations/event/' + this._eventId, {
                            data: jsonStr,
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            console.debug('success update DamageRport event');
                            chain.infoComplete(function () {
                                Router.moveTo('simulation/event', { scenarioId: this._scenarioId });
                            });
                        }, function (error) {
                            console.log(error);
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    });
                } else {

                    //更新確認ダイアログを表示
                    this.chain.confirm(this.setConfMsg(sendData, oldExtraDisasterId, false),
                        function (chain) {
                            var jsonStr = json.stringify(sendData);
                            var promise = Requester.put('/api/damageReports/' + this._damageReportId, {
                                data: jsonStr,
                                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                                handleAs: 'json',
                                preventCache: true
                            }).then(function (data) {
                                console.debug('success update DamageRport');
                                message = '完了しました。';
                                //                       }

                                // 作図情報を登録
                                var param = '?damageReportId=' + data.damageReportId;
                                return Requester.post('/api/damageReports/uploadjson' + param, {
                                    data: self.drawJson,
                                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                                    handleAs: 'json',
                                    preventCache: true
                                }).then(function () {
                                    console.debug('success upload json');
                                    //chain.infoComplete(function() {
                                    chain.info(message, '完了', function () {
                                        //ツリーの更新
                                        LayerStore.refreshAll();
                                        if (history.length === 1) {
                                            Router.moveTo('report');
                                        } else {
                                            // 一覧画面に移動
                                            self.leave();
                                        }
                                    });
                                }, function (err) {
                                    console.log(err);
                                    chain.info('作図ファイルの登録に失敗しました。', 'エラー', function () {
                                        // 一覧画面に移動
                                        if (history.length === 1) {
                                            Router.moveTo('report');
                                        } else {
                                            // 一覧画面に移動
                                            self.leave();
                                        }
                                    });
                                });
                            }, function (error) {
                                console.log('error update damagereport');
                                if (oldExtraDisasterId === '0' || this._extDamageReportId === null) {
                                    chain.infoError(error);
                                } else {
                                    chain.info('訂正に失敗しました。', 'エラー');
                                    //	                      chain.info('訂正に失敗しました。北海道システムへの登録・情報配信は行われません。', 'エラー');
                                }
                            });
                            //ローダーの表示
                            Loader.wait(promise);
                        });
                }
            },

            showDamageReportNumberDialog: function () {
                this.damageReportNumberDialog.show();
            },

            setConfMsg: function (sendData, extraDisasterId, registerFlg) {

                var registerCnfMsg = '';
                var message = '';
                if( sendData.damageType !== '19' && (sendData.damageScale !== '0' || sendData.urgencyType !== '0') && (sendData.numberData === null || this.checkNumberData(sendData.numberData))){
                    registerCnfMsg = '数値情報が未登録です。<br>このまま';
                    registerCnfMsg += registerFlg === true ? '続報登録' : '訂正';
                    message = registerCnfMsg + 'してよろしいですか？';
                    // 区本部ユーザの場合
                    // 以下のコメントアウト部分は単一区の被害の場合の対応で、複数区にまたがる被害の延期に伴い切り戻しが実施されている。
                    if (UserInfo.getRoleCd() === "R02022") {// && this._item.multiWardDamageFlg === 'false') {
                        message = registerCnfMsg + 'した後、大阪府システムに送信してよろしいですか？';
                    }
                }else{
                    registerCnfMsg += registerFlg === true ? '続報登録' : '訂正';
                    message = registerCnfMsg + 'します。よろしいですか？';
                    // 区本部ユーザの場合
                    // 以下のコメントアウト部分は単一区の被害の場合の対応で、複数区にまたがる被害の延期に伴い切り戻しが実施されている。
                    if (UserInfo.getRoleCd() === "R02022") {// && this._item.multiWardDamageFlg === 'false') {
                        message = registerCnfMsg + 'した後、大阪府システムに送信します。よろしいですか？';
                    }
                }

                return message;

            },
            /** 大阪府連携 */
            onRegistReport: function () {
                try {
                    var self = this;
                    var confirmMessage = '被害情報 詳細報を大阪府連携に登録します。よろしいですか？';
                    this.chain.confirm(confirmMessage, function (chain) {
                        Loader.wait(
                            Requester.post('/api/damageReports/publish?damageReportId=' + this._damageReportId).then(function () {
                                console.debug('success publish damageReport');
                                var completeMessage = '完了しました。';
                                chain.info(completeMessage, '完了', function () {
                                    // 前の画面に移動
                                    if (history.length === 1) {
                                        Router.moveTo('report');
                                    } else {
                                        // 一覧画面に移動
                                        self.leave();
                                    }
                                });
                            }, function (error) {
                                console.log('error publish damageReport');
                                console.debug(error);
                                chain.info('登録に失敗しました。', 'エラー');
                            })
                        );
                        //ローダーの表示
                    });
                } catch (e) {
                    console.error(e);
                }
                return false;
            },
            /**
             * 被害状況を削除する。
             */
            deleteDamageReport: function (deleteTarget) {
                var version = this.form.get('value').version;

                var message = '';

                message = '削除します。よろしいですか？';

                var uri = '';

                if (deleteTarget && deleteTarget === '2') {
                    // 過去報ごと削除
                    uri = '/api/damageReports/all/' + this.damageBasicInfo.admNum;

                    message = '削除します。よろしいですか？';
                    //                }
                } else {
                    uri = '/api/damageReports/' + this._damageReportId;

                    message = '削除します。よろしいですか？';
                    //                }

                }
                var self = this;
                //削除確認ダイアログの表示
                this.chain.confirm(message, lang.hitch(this, function (chain) {
                    var promise = Requester.del(uri, {
                        query: {
                            version: version,
                            displayId: this._damageReportId,
                            extraDisasterId: null
                        }
                    })
                        .then(lang.hitch(this, function (data) {
                            if (data.status !== null) {
                                message = '完了しました。';
                            } else {
                                message = '完了しました。';
                            }

                            chain.info(message, '完了', function () {
                                //                    chain.infoComplete(lang.hitch(this, function() {
                                //ツリーの更新
                                LayerStore.refreshAll();
                                // 一覧画面に移動
                                if (history.length === 1) {
                                    Router.moveTo('report');
                                } else {
                                    // 一覧画面に移動
                                    self.leave();
                                }
                            });
                        }), lang.hitch(this, function (error) {
                            console.log('error delete damagereport');
                            // 削除（過去報含む）
                            if (deleteTarget && deleteTarget === '2') {
                                if (this._extraDisasterId === '0' || this._extAdamNo === null) {
                                    chain.infoError(error);
                                } else {
                                    chain.info('削除に失敗しました。', 'エラー');
                                    //	                        chain.info('削除に失敗しました。北海道システムへの登録・情報配信は行われません。', 'エラー');
                                }
                                // 削除（最新のみ）
                            } else {
                                if (this._extraDisasterId === '0' || this._extDamageReportId === null) {
                                    chain.infoError(error);
                                } else {
                                    chain.info('削除に失敗しました。', 'エラー');
                                    //	                        chain.info('削除に失敗しました。北海道システムへの登録・情報配信は行われません。', 'エラー');
                                }
                            }
                        }));
                    //ローダーの表示
                    Loader.wait(promise);
                }));
                console.debug('[削除]ボタンがクリックされました。');
            },

            showDeleteDialog: function () {
                // 削除対象を選択できるのは、最新報かつ第2報以上のときとする
                if (this.damageBasicInfo.activeFlg === '1' && this.damageBasicInfo.seqNum > 1) {
                    this.damageReportDeleteDialog.show();
                } else {
                    this.deleteDamageReport();
                }
            },

            /**
             * 過去報を表示する。
             */
            onSeqNumChange: function (evt) {
                if (this._damageReportId !== String(evt)) {
                    this._damageReportId = String(evt);
                    this.initDetailPage(false);
                }
            },

            /**
             * 対応状況編集ダイアログを表示
             */
            showUpdateStatusDialog: function () {
                if (this._item.multiWardDamageFlg) {
                    return this.showUpdateReportStatusDialog();
                }
                var reportStatus = this._reportStatus;
                if (reportStatus === '31' || reportStatus === '32') {
                    this.chain.confirm('確定情報として登録した後、大阪府システムに送信します。よろしいですか？', lang.hitch(this, function (chain) {
                        chain.hide();
                        this._reportStatus = '41';
                        Loader.wait(Requester.post('/api/damageReports/updateStatus?damageReportId=' + this._damageReportId, {
                            data: json.stringify({ reportStatus: '41', version: this.version.get('value') }),
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            console.debug('success register damageReport');
                            chain.infoComplete(lang.hitch(this, function () {
                                chain.hide();
                                history.back();
                            }));
                        }), function (err) {
                            // いずれかに失敗時
                            chain.infoError(err);
                        });
                    }));
                }

                if (reportStatus === '12' && this._damageType !== '19') {
                    this.chain.confirm('所属確認済みとして登録しますか？', lang.hitch(this, function (chain) {
                        chain.hide();
                        this._reportStatus = '31';
                        Loader.wait(Requester.post('/api/damageReports/updateStatus?damageReportId=' + this._damageReportId, {
                            data: json.stringify({ reportStatus: '31', version: this.version.get('value') }),
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            console.debug('success register damageReport');
                            chain.infoComplete(lang.hitch(this, function () {
                                chain.hide();
                                history.back();
                            }));
                        }), function (err) {
                            // いずれかに失敗時
                            chain.infoError(err);
                        });
                    }));
                }
                if (reportStatus === '12' && this._damageType === '19') {
                    this.chain.confirm('確定情報として登録しますか？', lang.hitch(this, function (chain) {
                        chain.hide();
                        this._reportStatus = '41';

                        Loader.wait(Requester.post('/api/damageReports/updateStatus?damageReportId=' + this._damageReportId, {
                            data: json.stringify({ reportStatus: '41', version: this.version.get('value') }),
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            console.debug('success register damageReport');
                            chain.infoComplete(lang.hitch(this, function () {
                                chain.hide();
                                history.back();
                            }));
                        }), function (err) {
                            // いずれかに失敗時
                            chain.infoError(err);
                        });
                    }));
                }
                this._isChangeReportStatus = true;

            },


            /**
             * 複数区 報告者組織の報告ステータス変更
             */
            showUpdateReportStatusDialog: function () {
                // 他のステータスが確定報？
                var isKakuteHou = true;
                if (this._item.multiWardReportStatus1 && this._item.multiWardReportStatus1 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus2 && this._item.multiWardReportStatus2 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus3 && this._item.multiWardReportStatus3 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus4 && this._item.multiWardReportStatus4 !== '41') {
                    isKakuteHou = false;
                }

                return this.sendMultiReportStatus(this._item.multiWardReportId, this._item.multiWardReportStatus, isKakuteHou);
            },

            /**
             * 複数区 区1の報告ステータス変更
             */
            showMultiWard1StatusDialog: function () {
                // 他のステータスが確定報？
                var isKakuteHou = true;
                if (this._item.multiWardReportStatus2 && this._item.multiWardReportStatus2 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus3 && this._item.multiWardReportStatus3 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus4 && this._item.multiWardReportStatus4 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus && this._item.multiWardReportStatus !== '41') {
                    isKakuteHou = false;
                }

                return this.sendMultiReportStatus(this._item.multiWardReportId1, this._item.multiWardReportStatus1, isKakuteHou);
            },
            /**
             * 複数区 区2の報告ステータス変更
             */
            showMultiWard2StatusDialog: function () {
                // 他のステータスが確定報？
                var isKakuteHou = true;
                if (this._item.multiWardReportStatus1 && this._item.multiWardReportStatus1 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus3 && this._item.multiWardReportStatus3 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus4 && this._item.multiWardReportStatus4 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus && this._item.multiWardReportStatus !== '41') {
                    isKakuteHou = false;
                }

                return this.sendMultiReportStatus(this._item.multiWardReportId2, this._item.multiWardReportStatus2, isKakuteHou);
            },
            /**
             * 複数区 区3の報告ステータス変更
             */
            showMultiWard3StatusDialog: function () {
                // 他のステータスが確定報？
                var isKakuteHou = true;
                if (this._item.multiWardReportStatus1 && this._item.multiWardReportStatus1 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus2 && this._item.multiWardReportStatus2 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus4 && this._item.multiWardReportStatus4 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus && this._item.multiWardReportStatus !== '41') {
                    isKakuteHou = false;
                }

                return this.sendMultiReportStatus(this._item.multiWardReportId3, this._item.multiWardReportStatus3, isKakuteHou);
            },

            /**
             * 複数区 区4の報告ステータス変更
             */
            showMultiWard4StatusDialog: function () {
                // 他のステータスが確定報？
                var isKakuteHou = true;
                if (this._item.multiWardReportStatus1 && this._item.multiWardReportStatus1 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus2 && this._item.multiWardReportStatus2 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus3 && this._item.multiWardReportStatus3 !== '41') {
                    isKakuteHou = false;
                }
                if (this._item.multiWardReportStatus && this._item.multiWardReportStatus !== '41') {
                    isKakuteHou = false;
                }
                return this.sendMultiReportStatus(this._item.multiWardReportId4, this._item.multiWardReportStatus4, isKakuteHou);
            },


            sendMultiReportStatus: function (multiWardReportId, reportStatus, isKakuteHou) {

                if (reportStatus === '31' || reportStatus === '32') {
                    var msg = isKakuteHou ? '確定情報として登録した後、大阪府システムに送信します。よろしいですか？' : '確定情報として登録します。よろしいですか？';
                    return this.chain.confirm(msg, lang.hitch(this, function (chain) {
                        chain.hide();
                        this._reportStatus = '41';
                        Loader.wait(Requester.post('/api/damageReports/updateMultiStatus?multiWardReportId=' + multiWardReportId, {
                            data: json.stringify({ reportStatus: '41', version: this.version.get('value') }),
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            console.debug('success register damageReport');
                            chain.infoComplete(lang.hitch(this, function () {
                                chain.hide();
                                history.back();
                            }));
                        }), function (err) {
                            // いずれかに失敗時
                            chain.infoError(err);
                        });
                    }));
                }

                if (reportStatus === '12' && this._damageType !== '19') {
                    return this.chain.confirm('所属確認済みとして登録しますか？', lang.hitch(this, function (chain) {
                        chain.hide();
                        this._reportStatus = '31';
                        Loader.wait(Requester.post('/api/damageReports/updateMultiStatus?multiWardReportId=' + this._item.multiWardReportId, {
                            data: json.stringify({ reportStatus: '31', version: this.version.get('value') }),
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            console.debug('success register damageReport');
                            chain.infoComplete(lang.hitch(this, function () {
                                chain.hide();
                                history.back();
                            }));
                        }), function (err) {
                            // いずれかに失敗時
                            chain.infoError(err);
                        });
                    }));
                }
                if (reportStatus === '12' && this._damageType === '19') {
                    return this.chain.confirm('確定情報として登録しますか？', lang.hitch(this, function (chain) {
                        chain.hide();
                        this._reportStatus = '41';

                        Loader.wait(Requester.post('/api/damageReports/updateMultiStatus?multiWardReportId=' + this._item.multiWardReportId, {
                            data: json.stringify({ reportStatus: '41', version: this.version.get('value') }),
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            console.debug('success register damageReport');
                            chain.infoComplete(lang.hitch(this, function () {
                                chain.hide();
                                history.back();
                            }));
                        }), function (err) {
                            // いずれかに失敗時
                            chain.infoError(err);
                        });
                    }));
                }
            },
            /**
             * 続報のリストを、子オブジェクトseqNumを基準にソートする。
             */
            sortList: function (itemList) {
                itemList.sort(function (a, b) {
                    if (parseInt(a.seqNum, 10) < parseInt(b.seqNum, 10)) {
                        return -1;
                    }
                    if (parseInt(a.seqNum, 10) > parseInt(b.seqNum, 10)) {
                        return 1;
                    }
                    return 0;
                });
            },

            /**
             *
             * @param {number} itemList 要素一覧
             */
            _progressTransition: function (itemList) {
                // 先頭を取り出す
                var item = itemList[0];
                // 要素が存在＆未遷移なら処理を実行
                if (item && this.domNode) {
                    //処理を実行
                    this.seqNumSelect.set('value', itemList[0].damageReportId);


                    // 2番目以降の要素に対して同じ処理を実行
                    this.timer = setTimeout(lang.hitch(this, function () {
                        this._progressTransition(itemList.slice(1));
                    }), 1000);
                } else {
                    this._timer = null;
                }
            },



            /**
             * 対応状況グリッドを初期化する。
             * startup時、続報セレクタ選択時、対応状況の登録更新削除時
             */
            initDamageReportActionGrid: function (admNum, seqNum) {
                console.debug('initDamageReportActionGrid');
                this.actionStore = new IdisRest({
                    idProperty: 'damageReportActionId',
                    target: '/api/damageReports/action/' + admNum
                });
                var filter = new this.actionStore.Filter();
                filter = filter.eq('seqNum', seqNum);
                // 削除フラグがたっているものはグリッドに表示しない
                filter = filter.eq('delFlg', 0);

                this.grid.set('collection', this.actionStore.filter(filter));

                this.grid.on('updateActionButtonClick', lang.hitch(this, function (evt) {
                    //[編集中]メッセージの付け替え
                    if (this.actionForm.selectedAction && this.actionForm.selectedAction.editingMsg) {
                        domStyle.set(this.actionForm.selectedAction.editingMsg, 'display', 'none');
                    }
                    this.actionForm.selectedAction = evt.selectedAction;
                    domStyle.set(this.actionForm.selectedAction.editingMsg, 'display', '');
                    //フォームのアップデート
                    this.actionForm.showUpdateActionForm(evt.item);
                }));
            },

            /**
             * 対応履歴編集フォームを初期化する
             */
            initDamageReportActionForm: function () {
                // 編集フォームのregisterイベントを受け取る
                this.actionForm.on('register', lang.hitch(this, function (evt) {
                    // 入力チェック
                    if (!evt.value.content || evt.value.content === '') {
                        this.chain.info('コメントが入力されていません。', 'エラー');
                        return false;
                    }
                    var sendData = {
                        damageReportId: this._damageReportId,
                        content: evt.value.content,
                        hldStatus: evt.value.hldStatus,
                        name: evt.value.name
                    };
                    // 添付ファイルIDをセット
                    var attachFileIds = [];
                    array.forEach(evt.value.attachFiles, function (attachFile) {
                        attachFileIds.push(attachFile.damageReportAtcFileId);
                    });
                    sendData.attachFileIds = attachFileIds.join(',');
                    // 登録確認ダイアログ
                    this.chain.confirmAdd(lang.hitch(this, function (chain) {
                        // OKが押された場合
                        // 追加処理と共にローディング表示
                        Loader.wait(Requester.post('/api/damageReports/action', {
                            data: sendData,
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        })
                            .then(lang.hitch(this, function (data) {
                                console.log(data);
                                this.actionForm.clearActionForm();
                                this.actionStore = new IdisRest({
                                    idProperty: 'damageReportActionId',
                                    target: '/api/damageReports/action/' + this._admNum
                                });
                                var filter = new this.actionStore.Filter();
                                filter = filter.eq('seqNum', this._seqNum);
                                filter = filter.eq('delFlg', 0);
                                this.grid.set('collection', this.actionStore.filter(filter));
                                chain.infoComplete();
                                //グリッド更新後、位置を最下段にうつす
                                this.grid.scrollTo({ y: this.grid.bodyNode.scrollHeight });
                            }), function (err) {
                                chain.infoError(err);
                            }));
                    }));
                }));

                // 編集フォームのupdateイベントを受け取る
                this.actionForm.on('update', lang.hitch(this, function (evt) {
                    if (!evt.value.content || evt.value.content === '') {
                        this.chain.info('コメントが入力されていません。', 'エラー');
                        return false;
                    }
                    console.log(evt);
                    var sendData = {
                        damageReportActionId: evt.value.damageReportActionId,
                        content: evt.value.content,
                        hldStatus: evt.value.hldStatus,
                        name: evt.value.name
                    };
                    // 添付ファイルIDをセット
                    var attachFileIds = [];
                    array.forEach(evt.value.attachFiles, function (attachFile) {
                        attachFileIds.push(attachFile.damageReportAtcFileId);
                    });
                    sendData.attachFileIds = attachFileIds.join(',');
                    // 更新確認ダイアログ
                    this.chain.confirmPut(lang.hitch(this, function (chain) {
                        // OKが押された場合
                        // 追加処理と共にローディング表示
                        Loader.wait(
                            Requester.put('/api/damageReports/action/' + sendData.damageReportActionId, {
                                data: sendData,
                                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                                handleAs: 'json',
                                preventCache: true
                            })
                                .then(lang.hitch(this, function () {
                                    this.actionForm.clearActionForm();
                                    // 削除フラグがたっているものはグリッドに表示しない
                                    var filter = new this.actionStore.Filter();
                                    filter = filter.eq('seqNum', this._seqNum);
                                    filter = filter.eq('delFlg', 0);
                                    this.grid.set('collection', this.actionStore.filter(filter));
                                    //グリッド更新後、位置を最下段にうつす
                                    this.grid.scrollTo({ y: this.grid.bodyNode.scrollHeight });
                                    chain.infoComplete();
                                }), function (err) {
                                    chain.infoError(err);
                                }));
                    }));
                }));

                // 編集フォームのdeleteイベントを受け取る
                this.actionForm.on('delete', lang.hitch(this, function (evt) {
                    // 削除確認ダイアログの表示
                    this.chain.confirmDel(lang.hitch(this, function (chain) {
                        // 削除処理完了まではローディング画面を表示
                        Loader.wait(
                            Requester.del('/api/damageReports/action/' + evt.value.damageReportActionId)
                                .then(lang.hitch(this, function () {
                                    // 削除処理成功時
                                    this.actionForm.clearActionForm();
                                    // 削除フラグがたっているものはグリッドに表示しない
                                    var filter = new this.actionStore.Filter();
                                    filter = filter.eq('seqNum', this._seqNum);
                                    filter = filter.eq('delFlg', 0);
                                    this.grid.set('collection', this.actionStore.filter(filter));
                                    var y1 = this.grid.bodyNode.scrollTop;
                                    this.grid.scrollTo({ y: y1 });
                                    // 完了ダイアログの表示
                                    chain.infoComplete();
                                }), function (error) {
                                    // 削除処理失敗時
                                    // エラーダイアログの表示
                                    chain.infoError(error);
                                }));
                    }));
                }));
            },

            /**
             * 月や日付を2桁にゼロpaddingする
             */
            _zeroPadding: function (month) {
                return ('00' + month).slice(-2);
            },
            onChangeFacility: function () {
                var _facilityId = this.facilityId.get('value');
                // 施設が選択されてない場合は処理をSkip
                if (!_facilityId) {
                    return;
                }
                Loader.wait(
                    Requester.get('/api/facilityMaster/' + _facilityId)
                        .then(lang.hitch(this, function (data) {
                            console.log(data);
                            this.damageAddress.set('value', data.address);
                            this.publicFacility.set('checked', data.publicAvailableFlg);
                            this.damageAddressToMap();
                        })));
            }

        });
});
